import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Forms from "../../../utils/typeform"
import PresenterBlock from "../../blocks/presenter"
import Button from "../../buttons"
import Photos from "../../images/photos"
import TeamMembers from "../../images/team"
import Illustrations from "../../illustrations"

const ContactBlock = () => {
  const intl = useIntl()
  return (
    <PresenterBlock
      color="secondary"
      transparent={true}
      illustration={<Illustrations.Support.Constrained />}
      title={intl.formatMessage({ id: "blocks.contact.title" })}
      subtitle={intl.formatMessage({
        id: "blocks.contact.description",
      })}
      button={
        <Button
          color="secondary"
          small
          text={intl.formatMessage({
            id: "blocks.contact.callToAction",
          })}
          onClick={() => Forms.contact(intl)}
        />
      }
    />
  )
}

export default ContactBlock
