import React from "react"
import {
  FormattedMessage,
  IntlShape,
  navigate,
  useIntl,
} from "gatsby-plugin-intl"
import { darken, rgba } from "polished"
import styled, { useTheme } from "styled-components"
import { ThemeColor } from "../../../utils/colors"
import Button, { ButtonComponentProps } from "../../buttons"
import { H3, H4, P, PSmall } from "../../common/typography"
import { breakpoint, getFromTheme as theme } from "../../../utils/styles"
import { useState } from "react"
import HighlightedText from "../../common/highlightedText"
import Icons from "../../icons"

interface _PricingTier {
  key: string
  titleKey: string
  highlighted: boolean
  callToAction: (intl: IntlShape) => ButtonComponentProps
}

export interface IRatedPricingTier extends _PricingTier {
  rate: string
}

export interface IUnratedPricingTier extends _PricingTier {
  altRateKey: string
}

export type IPricingTier = IRatedPricingTier | IUnratedPricingTier

export const isRatedTier = (tier: IPricingTier): tier is IRatedPricingTier => {
  return "rate" in tier
}

export interface IPricingItem {
  pricingTiers: string[]
  titleKey: string
}

export interface ITierCategory {
  tiers: IPricingTier[]
  items: IPricingItem[]
  color: ThemeColor
  tabTitleKey: string
  titleKey: string
  descriptionKey: string
  path: string
}

const Selector = styled.div`
  display: flex;
  align-items: stretch;
  gap: 5px;
`

const SelectorItem = styled.div<{ color: ThemeColor }>`
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background-color: ${(props) => theme(`colors.web.${props.color}.xpale`)};
  padding: 32px 32px 30px;

  &:not(.selected) {
    border-bottom: 5px solid white;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: ${(props) =>
    darken(0.0001, theme(`colors.web.${props.color}.pale`)(props))};
    }

    :first-child {
      border-bottom-right-radius: 8px;
    }

    :nth-child(2) {
      border-bottom-left-radius: 8px;
    }
  }

  &.selected {
    border-bottom: 2px solid
      ${(props) => theme(`colors.web.${props.color}.xpale`)};
  }

  :first-child {
    border-top-left-radius: ${theme("borderRadius.cards")}px;
    border-top-right-radius: 8px;
  }

  :nth-child(2) {
    border-top-left-radius: 8px;
    border-top-right-radius: ${theme("borderRadius.cards")}px;
  }
`

const Amount = styled.span``
const Dollar = styled.span``
const PerMonth = styled(PSmall)``

const AltRateTitle = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

const Price = styled.div<{ color: ThemeColor }>`
  display: flex;
  align-items: stretch;

  &.absent {
    opacity: 0;
  }

  ${Amount} {
    font-size: 50px;
    line-height: 50px;
    max-height: 50px;
  }

  ${Dollar} {
    font-size: 32px;
    line-height: 32px;
    max-height: 32px;
    align-self: flex-start;
  }

  ${PerMonth} {
    align-self: flex-end;
  }

  ${Amount}, ${Dollar} {
    font-family: ${theme("font.family.heading")};
    color: ${(props) => theme(`colors.web.${props.color}.initial`)};
  }
`

const TierTitle = styled.div<{ color: ThemeColor }>`
  --border-width: 0px;
  --spacer-width: 8px;

  padding: calc(16px - var(--border-width)) calc(18px - var(--border-width))
    calc(12px - var(--border-width));
  border: var(--border-width) solid
    ${(props) => theme(`colors.web.${props.color}.pale`)};
  border-top-left-radius: calc(
    ${theme("borderRadius.cards")}px - var(--spacer-width)
  );
  border-top-right-radius: calc(
    ${theme("borderRadius.cards")}px - var(--spacer-width)
  );
  background-color: ${(props) => theme(`colors.web.${props.color}.xpale`)};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: var(--spacer-width);
  text-align: center;

  ${P} {
    font-weight: bold;
    color: ${(props) => theme(`colors.web.${props.color}.initial`)};
  }

  &[data-tierKey~="pro"] {
    background-color: ${(props) => theme(`colors.web.${props.color}.initial`)};
    border: 2px solid ${(props) => theme(`colors.web.${props.color}.initial`)};
    ${P} {
      color: white;
    }
  }
`

const TierCallToAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TierItem = styled.div`
  padding: 16px 30px;
  display: flex;
  gap: 8px;
`

const TierItemSeparator = styled.div<{ color: ThemeColor }>`
  height: 1px;
  background-color: ${(props) => theme(`colors.web.${props.color}.xpale`)};
`

const Tier = styled.div<{ color: ThemeColor }>`
  border-radius: ${theme("borderRadius.cards")}px;
  background-color: white;
  width: 400px;

  @media (max-width: ${breakpoint("large")}px) {
    width: calc((90vw - 3 * 32px) / 2);
  }

  @media (max-width: ${breakpoint("medium")}px) {
    width: calc(90vw - 2 * 64px);
    min-width: 320px;
  }

  display: grid;
  grid-template-columns: 132px auto;
  column-gap: 24px;

  ${TierTitle}, ${TierItem}, ${TierItemSeparator} {
    grid-column: span 2;
  }

  ${Button.Text} {
    white-space: normal;
  }

  ${Price} {
    grid-column: span 1;
  }

  ${TierCallToAction} {
    justify-self: flex-end;
    margin-right: 18px;
  }
`

const Tiers = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: ${breakpoint("medium")}px) {
    flex-direction: column;
  }

  ${Price} {
    margin: 32px 0 32px 18px;
  }
`

const Captions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 32px;
  gap: 12px;
`

const Body = styled.div<{ color: ThemeColor }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => theme(`colors.web.${props.color}.xpale`)};
  padding: 72px 32px 32px;
  border-bottom-left-radius: calc(${theme("borderRadius.cards")}px + 16px);
  border-bottom-right-radius: calc(${theme("borderRadius.cards")}px + 16px);

  ${Captions} {
    max-width: 550px;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 0;
  flex-direction: column;
  align-items: stretch;
`

const PricingBlock = ({
  categories,
  defaultCategory,
}: {
  categories: ITierCategory[]
  defaultCategory: ITierCategory
}) => {
  const intl = useIntl()
  const theme: any = useTheme()
  const [selectedCategory, setCategory] =
    useState<ITierCategory>(defaultCategory)

  return (
    <Wrapper>
      <Selector>
        {categories.map((category) => (
          <SelectorItem
            color={category.color}
            key={category.tabTitleKey}
            onClick={() => navigate(category.path)}
            className={
              selectedCategory.titleKey == category.titleKey ? "selected" : ""
            }
          >
            <H4 fluid>
              <FormattedMessage id={category.tabTitleKey} />
            </H4>
          </SelectorItem>
        ))}
      </Selector>
      <Body color={selectedCategory.color}>
        <Captions>
          <H3>
            <HighlightedText
              color={selectedCategory.color}
              textId={selectedCategory.titleKey}
            />
          </H3>
          <P>
            <FormattedMessage id={selectedCategory.descriptionKey} />
          </P>
        </Captions>
        <Tiers>
          {selectedCategory.tiers.map((tier) => (
            <Tier key={tier.key} color={selectedCategory.color}>
              <TierTitle color={selectedCategory.color} data-tierKey={tier.key}>
                <P>
                  <FormattedMessage id={tier.titleKey} />
                </P>
              </TierTitle>
              <Price color={selectedCategory.color}>
                {isRatedTier(tier) ? (
                  <>
                    <Amount>{tier.rate ?? "0"}</Amount>
                    <Dollar>$</Dollar>
                    <PerMonth color="pale">
                      <FormattedMessage id="pricing.perMonth" />
                    </PerMonth>
                  </>
                ) : (
                  <AltRateTitle>
                    <P small color={selectedCategory.color}>
                      <FormattedMessage id={tier.altRateKey} />
                    </P>
                  </AltRateTitle>
                )}
              </Price>
              {tier.callToAction != null ? (
                <TierCallToAction>
                  <Button {...tier.callToAction(intl)} />
                </TierCallToAction>
              ) : (
                <></>
              )}
              {selectedCategory.items.map((item) => (
                <>
                  <TierItemSeparator
                    color={selectedCategory.color}
                    key={`separator-${item.titleKey}`}
                  ></TierItemSeparator>
                  <TierItem key={`item-${item.titleKey}`}>
                    {item.pricingTiers.includes(tier.key) ? (
                      <Icons.Check.Small
                        symbolColor={theme.colors.web.accent.initial}
                      />
                    ) : (
                      <Icons.Close.Small
                        symbolColor={theme.colors.web.black.pale}
                      />
                    )}
                    <P
                      small
                      color={
                        item.pricingTiers.includes(tier.key)
                          ? "initial"
                          : "pale"
                      }
                    >
                      <FormattedMessage id={item.titleKey} />
                    </P>
                  </TierItem>
                </>
              ))}
            </Tier>
          ))}
        </Tiers>
      </Body>
    </Wrapper>
  )
}

export default PricingBlock
