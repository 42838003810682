import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import PricingBlock, { ITierCategory } from "."
import { PageContent, Section } from "../../common/layout"
import LogosBlock from "../home/logos"
import ContactBlock from "../blocks/contact"
import SignUp from "../../blocks/signUp"
import { clientsLogos } from "../../../pages"

interface PricingProps {
  categories: ITierCategory[]
  defaultCategory: ITierCategory
  sections?: JSX.Element
}

const PricingContent = ({
  sections,
  categories,
  defaultCategory,
}: PricingProps) => {
  const intl = useIntl()

  return (
    <PageContent>
      <Section t-md h-m-sm>
        <PricingBlock
          categories={categories}
          defaultCategory={defaultCategory}
        />
      </Section>

      <Section h-m-xlg t-lg>
        <LogosBlock
          title={intl.formatMessage({ id: "logos.clients.title" })}
          logoHeight={{ horizontal: 64, vertical: 48 }}
          logos={clientsLogos}
        />
      </Section>

      {sections ? sections : <></>}

      <Section t-lg h-centered h-m-sm>
        <SignUp />
      </Section>
    </PageContent>
  )
}

export default PricingContent
