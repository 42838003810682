import React from "react"
import { IntlProvider } from "gatsby-plugin-intl"

import Layout from "../../components/site/layout"
import Seo from "../../components/common/seo"
import {
  getLocale,
  getPathName,
  messagesForLanguage,
} from "../../utils/localized"
import {
  organizationCategory,
  tiersCategories,
} from "../../components/pages/pricing/content"
import PricingContent from "../../components/pages/pricing/page"
import { Section } from "../../components/common/layout"
import ContactBlock from "../../components/pages/blocks/contact"

const PricingPage = () => (
  <Layout>
    <PricingContent
      categories={tiersCategories}
      defaultCategory={organizationCategory}
      sections={
        <Section t-lg h-centered>
          <ContactBlock />
        </Section>
      }
    />
  </Layout>
)

export const Head = () => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo path={getPathName()} descriptionId="meta.description" />
    </IntlProvider>
  )
}

export default PricingPage
