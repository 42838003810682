/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

interface Meta {
  name?: string
  property?: string
  equiv?: string
  content: string
}
function Seo({
  title,
  description,
  descriptionId = "meta.description",
  titleId = "meta.title",
  path = "/",
  imageUrl,
  type,
  locale,
  meta = [],
}: {
  description?: string
  title?: string
  descriptionId?: string
  titleId?: string
  path: string
  type?: string
  meta?: Meta[]
  locale?: string
  imageUrl?: string
}) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `)

  const intl = useIntl()

  const metaDescription =
    description || (descriptionId && intl.formatMessage({ id: descriptionId }))

  const metaTitle = title || (titleId && intl.formatMessage({ id: titleId }))

  imageUrl ??= `${site.siteMetadata.siteUrl}/img/preview-${intl.locale}.png`

  const _meta: Meta[] = (
    [
      // HTML

      {
        name: `description`,
        content: metaDescription,
      },
      {
        equiv: "content-language",
        content: intl.locale,
      },

      // Facebook

      {
        property: `og:url`,
        content: `${site.siteMetadata.siteUrl}${path}`,
      },
      {
        property: `og:title`,
        content: metaTitle,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: type || "website",
      },
      {
        property: `og:locale`,
        content: `${locale || intl.locale}_CA`,
      },
      {
        property: `og:image`,
        content: imageUrl,
      },

      // Twitter

      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: metaTitle,
      },
      {
        name: `twitter:image`,
        content: imageUrl,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ] as Meta[]
  ).concat(meta)

  return (
    <>
      <title>{metaTitle}</title>
      {_meta.map(({ name, property, equiv, content }) => {
        return name ? (
          <meta key={name} name={name} content={content} />
        ) : property ? (
          <meta key={property} property={property} content={content} />
        ) : equiv ? (
          <meta key={equiv} httpEquiv={equiv} content={content} />
        ) : null
      })}
    </>
  )
}

export default Seo
