import { ITierCategory } from "."
import Forms from "../../../utils/typeform"

export const professionalsCategory: ITierCategory = {
  tiers: [
    {
      key: "free",
      titleKey: "pricing.professionnals.free.title",
      rate: "0",
      highlighted: false,
      callToAction: () => ({
        intlKey: "home.tryBraver",
        color: "secondary",
        small: true,
        href: "https://brv.app/l/app",
      }),
    },
    {
      key: "pro",
      titleKey: "pricing.professionnals.pro.title",
      highlighted: true,
      altRateKey: "pricing.organizations.customTitle",
      callToAction: (intl) => ({
        color: "secondary",
        small: true,
        intlKey: "pricing.contactUs",
        onClick: () => Forms.contact(intl),
      }),
    },
  ],
  items: [
    {
      titleKey: "pricing.professionnals.limited-voice-mails",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.profiles",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.friend-conversations",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.collaborations",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.personal-patients",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.network-search",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.visibility-control",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.availability-control",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.patient-content",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.professionnals.more-storage",
      pricingTiers: ["pro"],
    },
    {
      titleKey: "pricing.professionnals.non-braver-collabs",
      pricingTiers: ["pro"],
    },
    {
      titleKey: "pricing.professionnals.unlimited-voice-mails",
      pricingTiers: ["pro"],
    },
  ],
  color: "secondary",
  tabTitleKey: "pricing.tabs.professionnals",
  titleKey: "pricing.professionnals.title",
  descriptionKey: "pricing.professionnals.description",
  path: "/pricing/professionals",
}

export const organizationCategory: ITierCategory = {
  tiers: [
    {
      key: "free",
      titleKey: "pricing.organizations.free.title",
      rate: "0",
      highlighted: false,
      callToAction: () => ({
        intlKey: "pricing.comingSoon",
        color: "accent",
        small: true,
        disabled: true,
      }),
    },
    {
      key: "pro",
      titleKey: "pricing.organizations.pro.title",
      highlighted: true,
      altRateKey: "pricing.organizations.customTitle",
      callToAction: (intl) => ({
        color: "accent",
        small: true,
        intlKey: "pricing.contactUs",
        onClick: () => Forms.contact(intl),
      }),
    },
  ],
  items: [
    {
      titleKey: "pricing.organizations.geolocation-profiles",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.group-discussions",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.inbox",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.non-professionals-personnel",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.patients",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.fax",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.patient-content",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.non-braver-collabs",
      pricingTiers: ["free", "pro"],
    },
    {
      titleKey: "pricing.organizations.unlimited-voice-mails",
      pricingTiers: ["pro"],
    },
    {
      titleKey: "pricing.organizations.unlimited-storage",
      pricingTiers: ["pro"],
    },
    {
      titleKey: "pricing.organizations.3rd-party-integrations",
      pricingTiers: ["pro"],
    },
    {
      titleKey: "pricing.organizations.trajectories",
      pricingTiers: ["pro"],
    },
    {
      titleKey: "pricing.organizations.patient-and-family",
      pricingTiers: ["pro"],
    },
  ],
  color: "accent",
  tabTitleKey: "pricing.tabs.organizations",
  titleKey: "pricing.organizations.title",
  descriptionKey: "pricing.organizations.description",
  path: "/pricing/organizations",
}

export const tiersCategories: ITierCategory[] = [
  professionalsCategory,
  organizationCategory,
]
